import { Injectable } from '@angular/core';
import { AssociatedItemBaseStore } from '../../../../core/store/associated-item-base.store';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { ComplianceDetailsStore } from './compliance-details.store';
import { AssociatedItemConversion } from '../../../../shared/conversion/associated-item.conversion';
import { ComplianceAssociatedItemService } from '../services/compliance-associated-item.service';

@Injectable({ providedIn: 'root' })
export class ComplianceDetailsAssociatedItemStore extends AssociatedItemBaseStore {
  protected override service: any;

  constructor(
    currentUserPreferenceStore: CurrentUserPreferenceStore,
    private complianceAssociatedItemService: ComplianceAssociatedItemService,
    private complianceDetailsStore: ComplianceDetailsStore,
    private associatedItemConversion: AssociatedItemConversion
  ) {
    super(currentUserPreferenceStore, associatedItemConversion);
  }

  setMainItemId(): void {
    this.mainItemId = this.complianceDetailsStore.details.id;
  }

  setService(): void {
    this.service = this.complianceAssociatedItemService;
  }
}
