import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AssociatedItemServiceInterface } from '../../../../core/interfaces/service/associated-item-service.interface';
import { ComplianceAssociatedItemResponseDTO, IncidentAssociatedItemResponseDTO, ProcessAssociatedItemResponseDTO, RiskAssociatedItemResponseDTO } from '../../../../shared/dto/associated-item.dto';

@Injectable({
  providedIn: 'root',
})
export class ComplianceAssociatedItemService
  implements AssociatedItemServiceInterface
{
  apiUrl = environment.apiUrl + '/compliances';

  constructor(private http: HttpClient) {}

  getAssociatedRisks(
    complianceId: number,
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string
  ): Observable<RiskAssociatedItemResponseDTO[]> {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    const url = `${this.apiUrl}/${complianceId}/risks`;
    return this.http.get<RiskAssociatedItemResponseDTO[]>(url, { params });
  }

  getAssociatedIncidents(
    complianceId: number,
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string
  ): Observable<IncidentAssociatedItemResponseDTO[]> {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    const url = `${this.apiUrl}/${complianceId}/incidents`;
    return this.http.get<IncidentAssociatedItemResponseDTO[]>(url, { params });
  }

  getAssociatedControls(
    complianceId: number,
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string
  ): Observable<any[]> {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    const url = `${this.apiUrl}/${complianceId}/controls`;
    return this.http.get<any[]>(url, { params });
  }

  getAssociatedComplianceRequirements(
    complianceId: number,
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string
  ): Observable<ComplianceAssociatedItemResponseDTO[]> {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    const url = `${this.apiUrl}/${complianceId}/compliance-requirements`;
    return this.http.get<ComplianceAssociatedItemResponseDTO[]>(url, { params });
  }

  getAssociatedProcesses(
    complianceId: number,
    currentPage?: number | undefined,
    pageSize?: number | undefined,
    searchQuery?: string | undefined
  ): Observable<ProcessAssociatedItemResponseDTO[]> {
    throw new Error('Method not implemented.');
  }
}
